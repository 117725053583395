import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Scrollspy from "react-scrollspy"
import Logo from "../images/svgs/logo.svg"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import { ContainerStatics, TitleH1Statics } from "../components/global"
import Helmet from "react-helmet"

const FAQS = ({ location, intl }) => {
  const OptionsMenu = [
    {
      label: intl.formatMessage({ id: "menu_option_1_faqs" }),
      id: "AboutSafe365",
    },
    {
      label: intl.formatMessage({ id: "menu_option_11_faqs" }),
      id: "Watch",
    },
    {
      label: intl.formatMessage({ id: "menu_option_2_faqs" }),
      id: "SetUpAccount",
    },
    {
      label: intl.formatMessage({ id: "menu_option_3_faqs" }),
      id: "AddFamily",
    },
    {
      label: intl.formatMessage({ id: "menu_option_4_faqs" }),
      id: "Permissions",
    },
    {
      label: intl.formatMessage({ id: "menu_option_5_faqs" }),
      id: "GPSLocation",
    },
    {
      label: intl.formatMessage({ id: "menu_option_6_faqs" }),
      id: "Dashboard",
    },
    { label: intl.formatMessage({ id: "menu_option_7_faqs" }), id: "Map" },
    { label: intl.formatMessage({ id: "menu_option_8_faqs" }), id: "Activity" },
    { label: intl.formatMessage({ id: "menu_option_9_faqs" }), id: "Services" },
    {
      label: intl.formatMessage({ id: "menu_option_10_faqs" }),
      id: "Emergency",
    },
  ]
  const OptionsMenuId = [
    "AboutSafe365",
    "Watch",
    "SetUpAccount",
    "AddFamily",
    "Permissions",
    "GPSLocation",
    "Dashboard",
    "Map",
    "Activity",
    "Services",
    "Emergency",
  ]
  const SEOTitle = intl.formatMessage({ id: "SEO_title_faqs" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_faqs" })

  const getLinks = () => (
    <ListContainer
      items={OptionsMenuId}
      offset={-250}
      currentClassName="active"
    >
      {OptionsMenu.map(option => (
        <li key={option.id}>
          <AnchorLink offset="100" href={`#${option.id}`}>
            {option.label}
          </AnchorLink>
        </li>
      ))}
    </ListContainer>
  )

  const Questions = [
    {
      title: "1_block_title_faqs",
      text: "",
      answers: [
        {
          title: "1_1_block_subtitle_faqs",
          text: "1_1_block_text_faqs",
        },
        {
          title: "1_2_block_subtitle_faqs",
          text: "1_2_block_text_faqs",
        },
        {
          title: "1_3_block_subtitle_faqs",
          text: "1_3_block_text_faqs",
        },
        {
          title: "1_4_block_subtitle_faqs",
          text: "1_4_block_text_faqs",
        },
        {
          title: "1_5_block_subtitle_faqs",
          text: "1_5_block_text_faqs",
        },
        {
          title: "1_6_block_subtitle_faqs",
          text: "1_6_block_text_faqs",
        },
        {
          title: "1_7_block_subtitle_faqs",
          text: "1_7_block_text_faqs",
        },
      ],
    },
    {
      title: "11_block_title_faqs",
      text: "",
      answers: [
        {
          title: "11_1_block_subtitle_faqs",
          text: "11_1_block_text_faqs",
        },
        {
          title: "11_2_block_subtitle_faqs",
          text: "11_2_block_text_faqs",
        },
        {
          title: "11_3_block_subtitle_faqs",
          text: "11_3_block_text_faqs",
        },
        {
          title: "11_4_block_subtitle_faqs",
          text: "11_4_block_text_faqs",
        },
        {
          title: "11_5_block_subtitle_faqs",
          text: "11_5_block_text_faqs",
        },
        {
          title: "11_6_block_subtitle_faqs",
          text: "11_6_block_text_faqs",
        },
        {
          title: "11_7_block_subtitle_faqs",
          text: "11_7_block_text_faqs",
        },
        {
          title: "11_8_block_subtitle_faqs",
          text: "11_8_block_text_faqs",
        },
        {
          title: "11_9_block_subtitle_faqs",
          text: "11_9_block_text_faqs",
        },
        {
          title: "11_10_block_subtitle_faqs",
          text: "11_10_block_text_faqs",
        },
        {
          title: "11_11_block_subtitle_faqs",
          text: "11_11_block_text_faqs",
        },
        {
          title: "11_12_block_subtitle_faqs",
          text: "11_12_block_text_faqs",
        }
      ],
    },
    {
      title: "2_block_title_faqs",
      text: "2_block_text_faqs",
      answers: [
        {
          title: "2_1_block_subtitle_faqs",
          text: "2_1_block_text_faqs",
        },
        {
          title: "2_2_block_subtitle_faqs",
          text: "2_2_block_text_faqs",
        },
        {
          title: "2_3_block_subtitle_faqs",
          text: "2_3_block_text_faqs",
        },
        {
          title: "2_4_block_subtitle_faqs",
          text: "2_4_block_text_faqs",
        },
        {
          title: "2_5_block_subtitle_faqs",
          text: "2_5_block_text_faqs",
        },
      ],
    },
    {
      title: "3_block_title_faqs",
      text: "",
      answers: [
        {
          title: "3_1_block_subtitle_faqs",
          text: "3_1_block_text_faqs",
        },
        {
          title: "3_2_block_subtitle_faqs",
          text: "3_2_block_text_faqs",
        },
        {
          title: "3_3_block_subtitle_faqs",
          text: "3_3_block_text_faqs",
        },
      ],
    },
    {
      title: "4_block_title_faqs",
      text: "4_block_text_faqs",
      answers: [
        {
          title: "4_1_block_subtitle_faqs",
          text: "4_1_block_text_faqs",
        },
        {
          title: "4_2_block_subtitle_faqs",
          text: "4_2_block_text_faqs",
        },
        {
          title: "4_3_block_subtitle_faqs",
          text: "4_3_block_text_faqs",
        },
        {
          title: "4_4_block_subtitle_faqs",
          text: "4_4_block_text_faqs",
        },
        {
          title: "4_5_block_subtitle_faqs",
          text: "4_5_block_text_faqs",
        },
        {
          title: "4_6_block_subtitle_faqs",
          text: "4_6_block_text_faqs",
        },
      ],
    },
    {
      title: "5_block_title_faqs",
      text: "5_block_text_faqs",
      answers: [
        {
          title: "5_1_block_subtitle_faqs",
          text: "5_1_block_text_faqs",
        },
        {
          title: "5_2_block_subtitle_faqs",
          text: "5_2_block_text_faqs",
        },
        {
          title: "5_3_block_subtitle_faqs",
          text: "5_3_block_text_faqs",
        },
        {
          title: "5_4_block_subtitle_faqs",
          text: "5_4_block_text_faqs",
        },
        {
          title: "5_5_block_subtitle_faqs",
          text: "5_5_block_text_faqs",
        },
        {
          title: "5_6_block_subtitle_faqs",
          text: "5_6_block_text_faqs",
        },
        {
          title: "5_7_block_subtitle_faqs",
          text: "5_7_block_text_faqs",
        },
        {
          title: "5_8_block_subtitle_faqs",
          text: "5_8_block_text_faqs",
        },
        {
          title: "5_9_block_subtitle_faqs",
          text: "5_9_block_text_faqs",
        },
        {
          title: "5_10_block_subtitle_faqs",
          text: "5_10_block_text_faqs",
        },
      ],
    },
    {
      title: "6_block_title_faqs",
      text: "",
      answers: [
        {
          title: "6_1_block_subtitle_faqs",
          text: "6_1_block_text_faqs",
        },
        {
          title: "6_2_block_subtitle_faqs",
          text: "",
        },
        {
          title: "6_3_block_subtitle_faqs",
          text: "6_3_block_text_faqs",
        },
        {
          title: "6_4_block_subtitle_faqs",
          text: "6_4_block_text_faqs",
        },
        {
          title: "6_5_block_subtitle_faqs",
          text: "6_5_block_text_faqs",
        },
        {
          title: "6_6_block_subtitle_faqs",
          text: "6_6_block_text_faqs",
        },
        {
          title: "6_7_block_subtitle_faqs",
          text: "6_7_block_text_faqs",
        },
        {
          title: "6_8_block_subtitle_faqs",
          text: "6_8_block_text_faqs",
        },
        {
          title: "6_9_block_subtitle_faqs",
          text: "6_9_block_text_faqs",
        },
        {
          title: "6_10_block_subtitle_faqs",
          text: "6_10_block_text_faqs",
        },
        {
          title: "6_11_block_subtitle_faqs",
          text: "6_11_block_text_faqs",
        },
        {
          title: "6_12_block_subtitle_faqs",
          text: "6_12_block_text_faqs",
        },
        {
          title: "6_13_block_subtitle_faqs",
          text: "6_13_block_text_faqs",
        },
        {
          title: "6_14_block_subtitle_faqs",
          text: "6_14_block_text_faqs",
        },
        {
          title: "6_15_block_subtitle_faqs",
          text: "6_15_block_text_faqs",
        },
        {
          title: "6_16_block_subtitle_faqs",
          text: "6_16_block_text_faqs",
        },
        {
          title: "6_17_block_subtitle_faqs",
          text: "6_17_block_text_faqs",
        },
        {
          title: "6_18_block_subtitle_faqs",
          text: "6_18_block_text_faqs",
        },
        {
          title: "6_19_block_subtitle_faqs",
          text: "6_19_block_text_faqs",
        },
        {
          title: "6_20_block_subtitle_faqs",
          text: "6_20_block_text_faqs",
        },
        {
          title: "6_21_block_subtitle_faqs",
          text: "6_21_block_text_faqs",
        },
      ],
    },
    {
      title: "7_block_title_faqs",
      text: "",
      answers: [
        {
          title: "7_1_block_subtitle_faqs",
          text: "7_1_block_text_faqs",
        },
        {
          title: "7_2_block_subtitle_faqs",
          text: "7_2_block_text_faqs",
        },
        {
          title: "7_3_block_subtitle_faqs",
          text: "7_3_block_text_faqs",
        },
        {
          title: "7_4_block_subtitle_faqs",
          text: "7_4_block_text_faqs",
        },
        {
          title: "7_5_block_subtitle_faqs",
          text: "7_5_block_text_faqs",
        },
        {
          title: "7_6_block_subtitle_faqs",
          text: "7_6_block_text_faqs",
        },
        {
          title: "7_7_block_subtitle_faqs",
          text: "7_7_block_text_faqs",
        },
        {
          title: "7_8_block_subtitle_faqs",
          text: "7_8_block_text_faqs",
        },
      ],
    },
    {
      title: "8_block_title_faqs",
      text: "",
      answers: [
        {
          title: "8_1_block_subtitle_faqs",
          text: "8_1_block_text_faqs",
        },
        {
          title: "8_2_block_subtitle_faqs",
          text: "8_2_block_text_faqs",
        },
        {
          title: "8_3_block_subtitle_faqs",
          text: "8_3_block_text_faqs",
        },
      ],
    },
    {
      title: "9_block_title_faqs",
      text: "9_block_text_faqs",
      answers: [
        {
          title: "9_1_block_subtitle_faqs",
          text: "9_1_block_text_faqs",
        },
        {
          title: "9_2_block_subtitle_faqs",
          text: "9_2_block_text_faqs",
        },
        {
          title: "9_3_block_subtitle_faqs",
          text: "9_3_block_text_faqs",
        },
        {
          title: "9_4_block_subtitle_faqs",
          text: "9_4_block_text_faqs",
        },
        {
          title: "9_5_block_subtitle_faqs",
          text: "9_5_block_text_faqs",
        },
      ],
    },
    {
      title: "10_block_title_faqs",
      text: "10_block_text_faqs",
      answers: [],
    },
  ]

  return (
    <>
    <Helmet>
      <link rel='canonical' href='https://www.durcal.com/es/preguntas-frecuentes'/>
    </Helmet>
    <Layout {...{ location }}>
      <SEO title={SEOTitle} description={SEODescription} />
      https://www.durcal.com/es/preguntas-frecuentes
      <LogoWrapper>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrapper>
      <ContainerStaticsStyled>
        <TitleH1Statics>
          {intl.formatMessage({ id: "main_title_faqs" })}
        </TitleH1Statics>
        <FAQContainer>
          <MenuContainer>{getLinks()}</MenuContainer>
          <InfoContainer>
            {OptionsMenuId.map((optionId, index) => (
              <div id={optionId} key={optionId}>
                <h3>{intl.formatMessage({ id: Questions[index].title })}</h3>
                {Questions[index].text && (
                  <div className="block">
                    {intl
                      .formatMessage({ id: Questions[index].text })
                      .split("\n")
                      .map((item, i) => (
                        <p className="text" key={i}>
                          {item}
                        </p>
                      ))}
                  </div>
                )}
                {Questions[index].answers.map((answer, index) => (
                  <div className="block" key={index}>
                    <h5>{intl.formatMessage({ id: answer.title })}</h5>
                    {answer.text &&
                      intl
                        .formatMessage({ id: answer.text })
                        .split("\n")
                        .map((item, i) => (
                          <p className="text" key={i}>
                            {item}
                          </p>
                        ))}
                  </div>
                ))}
              </div>
            ))}
          </InfoContainer>
        </FAQContainer>
      </ContainerStaticsStyled>
    </Layout>
    </>
  )
}

const LogoWrapper = styled.div`
  width: 100%;
  padding: 50px 20px 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const ContainerStaticsStyled = styled(ContainerStatics)`
  @media (min-width: ${props => props.theme.screen.md}) {
    margin-top: 120px;
  }
`

const FAQContainer = styled.div`
  margin-top: 56px;
  display: flex;
`

const MenuContainer = styled.div`
  margin-right: 38px;
  min-width: max-content;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`

const InfoContainer = styled.div`
  h3 {
    color: ${({ theme }) => theme.color.purple};
    ${({ theme }) => theme.font_size.large};
    margin: 0 0 30px 0;
  }

  .block {
    margin-bottom: 40px;
  }

  h5 {
    color: ${({ theme }) => theme.color.black.purple};
    ${({ theme }) => theme.font_size.regular};
    margin: 0 0 20px 0;
  }

  .text {
    color: ${({ theme }) => theme.color.black.greydark};
    ${({ theme }) => theme.font_size.xsmall};
    font-family: ${props => props.theme.font.medium};
    margin: 0 0 16px 0;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    h3 {
      ${({ theme }) => theme.font_size.regular};
    }

    h5 {
      ${({ theme }) => theme.font_size.small};
    }
  }
`
const ListContainer = styled(Scrollspy)`
  list-style: none;
  padding: 0 0 40px 0;
  margin-left: -6px;
  position: sticky;
  top: 125px;

  li {
    margin-bottom: 24px;

    a {
      color: ${({ theme }) => theme.color.grey.grey03};
      padding: 8px 12px;
      border-radius: 51px;

      &:hover {
        background: ${({ theme }) => theme.color.grey.grey01};
      }

      @media (max-width: ${props => props.theme.screen.md}) {
        ${({ theme }) => theme.font_size.xxsmall};
      }
    }
  }
  .active {
    a {
      background: ${({ theme }) => theme.color.purpleLight};
      color: ${({ theme }) => theme.color.white};

      &:hover {
        background: ${({ theme }) => theme.color.purpleLight};
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`

export default injectIntl(FAQS)
