import React from "react"
import styled from "styled-components"
import BaseButton from "../../images/svgs/base.svg"

const ButtonIcon = ({ children, to, width = "48px", height = "48px" }) => (
  <ButtonIconWrapper {...{ width, height }}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <BaseButton />
      <div className="children">{children}</div>
    </a>
  </ButtonIconWrapper>
)

const ButtonIconWrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  a {
    svg {
      width: ${({ width }) => width};
      height: ${({ height }) => height};

      path {
        transition: 0.4s;
      }
    }

    &:hover {
      svg {
        path {
          fill: ${props => props.theme.color.purpleDeep};
        }
      }
    }
  }

  .children {
    position: absolute;
    top: 25%;
    left: 25%;
  }
`

export default ButtonIcon
