import React, { useEffect } from "react"
import anime from "animejs/lib/anime.es.js"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import theme from "../../../styles/theme"
import GlobalStyles from "../../../styles/GlobalStyles"
import Navigation from "../navigation/navigation"
import Footer from "./footer"
import ToastNotification from "../toastNotification"
import ScrollBar from "../../home/scrollBar"
import "../../../static/fonts/fonts.css"

const Layout = ({ location, children, showToast, showScrollBar, intl }) => {
  useEffect(() => {
    anime.timeline({ loop: false }).add({
      targets: document.body,
      opacity: 1,
      duration: 500,
      easing: "easeInExpo",
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <LayoutWrapper>
        <GlobalStyles />
        {showToast && <ToastNotification />}
        <Navigation {...{ location }} />
        <ScrollBar {...{ showScrollBar }} />
        {children}
        <Footer {...{ location }} />
      </LayoutWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutWrapper = styled.div`
  position: relative;
`

export default injectIntl(Layout)
