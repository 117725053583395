import styled from "styled-components"
import Img from "gatsby-image"
import { Container } from "../../global"

export const Nav = styled.nav`
/display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    padding: 40px 0 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: ${props => (props.scrolled ? `white` : null)};
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding: 0 40px;
  }
`

export const Brand = styled.div`
  margin: 0 40px 0 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    .white {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 370px;
`

export const StyledLogoImage = styled(Img)`
  width: 24px;
`
