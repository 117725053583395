import React from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import {
  Nav,
  Brand,
  StyledContainer,
  ActionsContainer,
  StyledLogoImage,
} from "./style"
import Logo from "../../../images/svgs/logo.svg"
import { APP_STORES_LINK, checkLanguage, paths } from "../../../utils"
import ButtonIcon from "../buttonIcon"
import Button from "../button"

const Navigation = ({ intl, location }) => {
  const logos = useStaticQuery(graphql`
    query {
      appleStore: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "ic_apple_store" }
      ) {
        childImageSharp {
          fluid(maxWidth: 24) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      googlePlay: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "ic_google_play" }
      ) {
        childImageSharp {
          fluid(maxWidth: 24) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Nav>
      <StyledContainer>
        <Brand>
          <Link to={`/`} onClick={goToTop}>
            <Logo alt="Durcal" id="LogoDurcal" />
          </Link>
        </Brand>

        <ActionsContainer>
          <Button
            to={`/${checkLanguage(location, intl)}${
              paths[intl.locale].pulsera
            }`}
            btnType="coral"
            rounded
            external={false}
          >
            {intl.formatMessage({ id: "buy_watch_button_header" })}
          </Button>
          <ButtonIcon to={APP_STORES_LINK.apple}>
            <StyledLogoImage fluid={logos.appleStore.childImageSharp.fluid} />
          </ButtonIcon>
          <ButtonIcon to={APP_STORES_LINK.google}>
            <StyledLogoImage fluid={logos.googlePlay.childImageSharp.fluid} />
          </ButtonIcon>
        </ActionsContainer>
      </StyledContainer>
    </Nav>
  )
}

export default injectIntl(Navigation)
