import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import lottie from "lottie-web"
import animation from "../../animations/ic_arrow_scroll.json"
import { Container } from "../global"

const ScrollBar = ({ showScrollBar }) => {
  const [scrollProgress, setScrollProgress] = useState(0)
  const arrowScrollLottieRef = useRef(null)

  const LIMIT_SCROLL = 0.98

  function handlePageScroll() {
    const totalScroll = document.documentElement.scrollTop
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scroll = `${totalScroll / windowHeight}`

    setScrollProgress(scroll)
  }

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: arrowScrollLottieRef.current,
      loop: false,
      autoplay: true,
      animationData: animation,
    })

    lottieAnimation.addEventListener("complete", function() {
      setTimeout(function() {
        lottieAnimation.goToAndPlay(0)
      }, 10000)
    })

    function watchScroll() {
      window.addEventListener("scroll", handlePageScroll)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", handlePageScroll)
    }
  }, [])

  return (
    <ScrollBarContainer {...{ showScrollBar }}>
      <StyledContainer>
        <ScrollBarWrapper
          style={{
            display: scrollProgress > LIMIT_SCROLL ? "none" : "flex",
          }}
        >
          <div
            style={{
              transform: `scaleY(${scrollProgress})`,
              transformOrigin: `top`,
            }}
          />
        </ScrollBarWrapper>
        <LottieWrapper
          style={{
            transform: scrollProgress > LIMIT_SCROLL ? "rotateX(180deg)" : "",
            marginTop: scrollProgress > LIMIT_SCROLL ? "500px" : "100px",
          }}
          ref={arrowScrollLottieRef}
        />
      </StyledContainer>
    </ScrollBarContainer>
  )
}

const ScrollBarContainer = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    ${({ showScrollBar }) =>
      showScrollBar ? "display: flex" : "display: none"};
    position: fixed;
    width: 100%;
    top: 30%;
    z-index: 10;
  }
`

export const StyledContainer = styled(Container)`
  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 48px;
  }
`

const ScrollBarWrapper = styled.div`
  z-index: 99;
  height: 320px;
  width: 5px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.color.coralLight};
  overflow: hidden;
  display: flex;

  div {
    background-color: ${({ theme }) => theme.color.coral};
    width: 5px;
  }
`

const LottieWrapper = styled.div`
  width: 40px;
  margin: 0 -10px 0 0;

  @media (min-width: ${props => props.theme.screen.lg}) {
    margin: 100px -12px 0 0;
  }
`

export default ScrollBar
